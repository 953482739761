import { FILTERS_EQUAL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("select-filter", { key: "CanSizeId", attrs: { "advanced-filters": _vm.canSizesAdvancedFilters, "delete-filter": "IsInactive", "label": "Can Size", "lazy": "", "mode": "multiple", "placeholder": "Select Can Size", "reference": "sterilization.common.can-sizes", "source": "id", "source-label": "name" } }), _c("operator-filter", { key: "Capacity", attrs: { "label": "Cooker (Outer)", "type": "number" } }), _c("operator-filter", { key: "PCCapacity", attrs: { "label": "P.C.", "type": "number" } }), _c("operator-filter", { key: "ACCapacity", attrs: { "label": "A.C.", "type": "number" } }), _c("operator-filter", { key: "TotalCapacity", attrs: { "label": "Total (Outer)", "type": "number" } }), _c("operator-filter", { key: "CapacityInner", attrs: { "label": "Cooker (Inner)", "type": "number" } }), _c("operator-filter", { key: "TotalCapacityInner", attrs: { "label": "Total (Inner)", "type": "number" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterCookerCapacityDrawer",
  data() {
    return {
      canSizesAdvancedFilters: [
        ["Facility", { operator: FILTERS_EQUAL, value: "PK,PP,PT" }]
      ]
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterCookerCapacityDrawer = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "filter-cooker-capacities" }, [_c("resource", { attrs: { "name": "sterilization.common.can-sizes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "sterilization.cooker-capacities", "api-url": _vm.apiUrl, "filter": _vm.FilterCookerCapacityDrawer, "redirect-route": "/sterilization-specifications/cooker-capacities" } })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "FilterCookerCapacity",
  data() {
    return {
      FilterCookerCapacityDrawer,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
